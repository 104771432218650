<template>
    
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/Mechanism/LearnPackage"
            >学习包 /
          </router-link>
          <span class="crumbs_item crumbs_last"
            >学习包{{ $route.query.operation == 1 ? "新建" : "编辑" }}</span
          >
        </span>
      </div>
    </div>

    <div class="all_content_box">
      <div class="L_R_inner">
        <span class="all_left_name all_required">学习包名称：</span>
        <div class="right_Div">
          <a-input
            class="all_input"
            v-model="form.name"
            placeholder="请输入学习包名称"
          />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">列表图：</span>
        <div class="right_Div">
          <a-upload
            name="picture"
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
            list-type="picture-card"
            :showUploadList="false"
            :loading="loadingPc"
            :customRequest="customList"
          >
            <img
              v-if="form.picture"
              :src="form.picture"
              style="max-width: 180px"
              alt="avatar"
            />
            <div v-else>
              <a-icon :type="loadingPc ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </div>
      </div>

      <div class="L_R_inner">
        <span class="all_left_name all_required">详情图：</span>
        <div class="right_Div">
          <a-upload
            name="detail"
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
            list-type="picture-card"
            :showUploadList="false"
            :loading="loadingPc"
            :customRequest="customList"
          >
            <img
              v-if="form.detailPicture"
              :src="form.detailPicture"
              style="max-width: 180px"
              alt="avatar"
            />
            <div v-else>
              <a-icon :type="loadingPc ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </div>
      </div>

      <div class="L_R_inner">
        <span class="all_left_name all_required">简介：</span>
        <div class="right_Div">
          <a-textarea
            placeholder="请输入学习包简介"
            v-model="form.abstracts"
            style="resize: none"
          />
        </div>
      </div>

      <div class="L_R_inner">
        <span class="all_left_name all_required">包含商品：</span>
        <div class="right-box">
          <div class="box-first">
            <div class="right_Div">
              <a-button type="primary" class="btn" @click="addProduct()"
                >添加</a-button
              >
              <span class="tips">请至少添加2种商品</span>
            </div>
          </div>
        </div>
      </div>
      <a-table
        class="table-template"
        style="margin-left: 155px; width: 800px"
        :columns="columns"
        :rowKey="(item) => item.productId"
        :data-source="productJson"
        @change="onPage"
        :pagination="{
          total: total,
          showTotal: (res) => `共${total}条`,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '50', '100'],
        }"
      >
        <template slot="index" slot-scope="item, row, index">
          {{ (tableQuery.pageNumber - 1) * tableQuery.pageSize + index + 1 }}
        </template>

        <!-- 原价 -->
        <template slot="price" slot-scope="item">
          ￥{{ item ? Number(item).toFixed(2) : "0.00" }}
        </template>

        <template slot="operation" slot-scope="item, row, index">
          <a style="color: #3681f0" @click="onSelectIndexDel(1, index)">删除</a>
        </template>
      </a-table>
      <div class="L_R_inner">
        <span class="all_left_name">总价格：</span>
        <div class="right_Div redtext">
          ￥{{ form.totalPrice.toFixed(2) }}元
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">学习包单价：</span>
        <div class="right_Div">
          <a-input
            class="all_input else_input"
            v-model="form.couponPrice"
            @blur="inputContent"
            placeholder=""
          />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">价格设置：</span>
        <div class="right_Div" style="display: block">
          <a-radio-group v-model="form.priceType">
            <a-radio :style="radioStyle" :value="1"
              >仅可按学习包单价购买</a-radio
            >
            <div class="flex-box">
              <a-radio :style="radioStyle" :value="2"
                >满足条件可按机构门槛价格购买</a-radio
              >
              <a
                style="color: #3681f0"
                v-show="form.priceType == 2"
                @click="onThresholdAdd"
                >添加购买门槛</a
              >
            </div>
          </a-radio-group>
          <!-- 优惠条件 -->
          <div class="discount" v-show="form.priceType == 2">
            <div v-for="(item, index) in priceJson" :key="index">
              <div class="item">
                购买数量满足
                <a-input-number :min="0" v-model="item.startNumber" />
                份时，享受价格
                <a-input-number :min="0" v-model="item.favorPrice" /> 元
                <a v-show="index != 0" @click="onThresholdDel(index)">删除</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="L_R_inner" v-if="freightShow">
        <span class="all_left_name all_required">运费设置：</span>
        <div class="right_Div">
          <a-select
            style="width: 200px"
            placeholder="请选择运费模板"
            v-model="form.freightId"
          >
            <a-select-option
              :value="item.freightId"
              v-for="item in freightData"
              :key="item.freightId"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </div>
      </div>
      <div class="L_R_inner">
        <div class="Up_Down_inner">
          <span class="all_left_name all_required">详情介绍：</span>
          <UE
            editorId="introduceEditor2"
            :initContent="form.introduce"
            @input="getContent"
          />
        </div>
      </div>
      <div class="L_R_inner">
        <span class="all_left_name all_required">上架设置：</span>
        <div class="right-box">
          <a-radio-group v-model="form.publishFlag">
            <div class="box-first" style="width: 570px">
              <div class="right_Div">
                <a-radio :value="1">立即上架</a-radio>
                <a-radio :value="0">暂不上架</a-radio>
              </div>
            </div>
          </a-radio-group>
        </div>
      </div>
    </div>

    <div class="button">
      <a-button
        type="primary"
        @click="onSavaForm()"
        :loading="loading"
        class="btn"
        >确认</a-button
      >
      <a-button class="all_boder_btn btn" @click="$router.go(-1)"
        >取消</a-button
      >
    </div>

    <a-modal
      v-model="relation"
      width="1000px"
      title="商品列表"
      :centered="true"
      :closable="false"
    >
      <div class="search-box">
        <a-select
          class="search-input"
          v-model="productForm.type"
          placeholder="请选择商品类型"
          allowClear
          v-show="false"
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option :value="1">课程</a-select-option>
          <a-select-option :value="2">图书</a-select-option>
          <a-select-option :value="3">教具</a-select-option>
          <!-- <a-select-option :value="4">模拟考试</a-select-option>
          <a-select-option :value="5">电子照片</a-select-option>
          <a-select-option :value="6">延期</a-select-option>
          <a-select-option :value="7">补考</a-select-option>
          <a-select-option :value="8">直播</a-select-option>
          <a-select-option :value="9">其他</a-select-option> -->
        </a-select>
        <a-input
          class="search-input"
          v-model="productForm.productName"
          placeholder="请输入商品名称"
        />
        <a-button class="search-btn" type="primary" @click="onProductSearch"
          >搜索</a-button
        >
      </div>
      <div class="table-box">
        <a-table
          class="table-template1"
          :row-selection="{
            columnTitle: '选择',
            selectedRowKeys: selectedProductRowKeys,
            onChange: onSelectProduct,
            columnWidth: '70px',
          }"
          bordered
          :rowKey="(item) => item.id"
          :columns="productcolumns"
          :data-source="productData"
          :loading="productLoad"
          @change="onProductPage"
          :pagination="{
            total: productTotal,
            current: productForm.pageNum,
            defaultpageSize: productForm.pageSize,
            showSizeChanger: true,
            showTotal: function (productTotal, range) {
              return `共${productTotal}条`;
            },
          }"
        >
          <template slot="index" slot-scope="item, row, index">
            {{ (productForm.pageNum - 1) * productForm.pageSize + index + 1 }}
          </template>
          <!-- 商品类型 -->
          <template slot="productType" slot-scope="item">
            <!-- 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.其他 -->
            <span v-if="item == 1">课程</span>
            <span v-else-if="item == 2">图书</span>
            <span v-else-if="item == 3">教具</span>
            <span v-else-if="item == 4">模拟考试</span>
            <span v-else-if="item == 5">电子照片</span>
            <span v-else-if="item == 6">延期</span>
            <span v-else-if="item == 7">补考</span>
            <span v-else-if="item == 8">直播</span>
            <span v-else-if="item == 9">其他</span>
            <span v-else>-</span>
          </template>
        </a-table>

        <a-table
          class="table-template2"
          style="width: 30%"
          bordered
          :rowKey="(item) => item.id"
          :columns="selectcolumns"
          :data-source="selectData"
          :pagination="{
            total: selectTotal,
            showTotal: (res) => `共${selectTotal}条`,
          }"
        >
          <template slot="num">
            当前已选中 <span class="blueText">{{ selectTotal }}</span> 个商品
          </template>

          <template slot="operation" slot-scope="item, row, index">
            <a @click="onSelectIndexDel(2, index)" class="blueText">清除</a>
          </template>
        </a-table>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="relationOk">确认</a-button>
        <a-button @click="relation = false">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import UE from "@/components/UE.vue";
const productcolumns = [
  {
    title: "序号",
    align: "center",
    width: "70px",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "商品类型",
    align: "center",
    width: "140px",
    dataIndex: "productType",
    scopedSlots: {
      customRender: "productType",
    },
  },
  {
    title: "商品名称",
    ellipsis: true,
    align: "center",
    dataIndex: "productName",
  },
];
const selectcolumns = [
  {
    align: "center",
    dataIndex: "productName",
    width: 120,
    ellipsis: true,
    colSpan: 2,
    scopedSlots: {
      customRender: "productName",
      title: "num",
    },
  },
  {
    title: "操作",
    align: "center",
    colSpan: 0,
    width: 40,
    dataIndex: "operation",
    scopedSlots: {
      customRender: "operation",
    },
  },
];
const columns = [
  {
    title: "序号",
    align: "center",
    width: "80px",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "商品名称",
    ellipsis: true,
    align: "center",
    width: "300px",
    dataIndex: "productName",
  },
  {
    title: "数量",
    align: "center",
    dataIndex: "quantity",
    width: "120px",
    // scopedSlots: {
    //   customRender: "count",
    // },
  },
  {
    title: "机构端单价",
    align: "center",
    dataIndex: "price",
    scopedSlots: {
      customRender: "price",
    },
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "operation",
    scopedSlots: {
      customRender: "operation",
    },
  },
];
export default {
  // 可用组件的哈希表
  components: {
    UE,
  }, // 接收传值
  props: {}, // 数据对象
  data() {
    return {
      value: 1,

      loading: false,
      columns,
      total: 0,
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      relation: false,
      selectcolumns,

      productArry: [], // 已选商品--暂存
      selectData: [], // 已选商品
      selectTotal: 0,

      selectedProductRowKeys: [],
      productData: [], // 商品列表
      productcolumns, // 商品表头
      productTotal: 0, // 商品数量
      productLoad: false,
      // 商品参数
      productForm: {
        productName: "",
        pageNum: 1,
        pageSize: 10,
      },

      radioStyle: {
        display: "flex",
        alignItems: "center",
        height: "30px",
        lineHeight: "30px",
      },

      form: {
        id: "",
        name: "", //套餐名称
        picture: "", //主图
        detailPicture: "", //详情图
        abstracts: "", // 套餐简介
        productJson: [],
        totalPrice: 0, // 原总价
        couponPrice: 0, // 学习包单价
        priceType: 1, // 价格配置  1.单价购买  2.阶梯购买
        priceJson: [], // 阶梯配置
        freightId: undefined,
        introduce: "",
        publishFlag: 1,
      },

      productJson: [], // 关联商品

      priceJson: [
        // 阶梯配置
        {
          type: 2,
          startNumber: "0",
          favorPrice: "0",
          deleted: 0,
        },
      ],

      freightShow: false, // 运费配置是否展示

      tableQuery: {
        pageNumber: 0,
        pageSize: 0,
      },

      loadingPc: false,
      mobileIndex: 0,
      pcIndex: 0,

      tipShow: false,

      freightData: [],
    };
  }, // 事件处理器
  methods: {
    moment,

    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },

    // 禁止选择今天之前的日期
    disabledDate(current) {
      return current && current < moment().subtract(1, "days").endOf("day");
    },

    disabledDateTime() {
      return {
        disabledHours: () => this.range(0, 24).splice(4, 20),
        disabledMinutes: () => this.range(30, 60),
        disabledSeconds: () => [55, 56],
      };
    },

    // 添加购买门槛
    onThresholdAdd() {
      this.priceJson.push({
        type: 2,
        startNumber: "0",
        favorPrice: "0",
        deleted: 0,
      });
    },
    // 删除购买门槛
    onThresholdDel(index) {
      // console.log(index,this.priceJson);
      this.priceJson.splice(index, 1);
    },

    // 分页
    onPage(e) {
      this.tableQuery.pageNumber = e.current;
      this.tableQuery.pageSize = e.pageSize;
    },
    // 添加商品
    addProduct() {
      this.relation = true;
      this.selectedProductRowKeys = [];
      this.selectData = [];
      this.productJson.forEach((element) => {
        this.selectedProductRowKeys.push(element.productId);
        this.selectData.push({
          orgPrice: element.price,
          id: element.productId,
          productName: element.productName,
          productType: element.productType,
          quantity: element.quantity,
        });
      });
      this.selectTotal = this.selectData.length;
      this.total = this.productJson.length;
    },
    // 商品分页
    onProductPage(e) {
      this.productForm.pageNum = e.current;
      this.productForm.pageSize = e.pageSize;
      this.getProductList();
    },

    // 计算总价格
    onSumPrice() {
      let count = 0;
      this.productJson.forEach((element) => {
        if (element.price) {
          count = element.price * element.quantity + count;
        }
      });
      this.form.totalPrice = count;
    },

    // 非空字段校验
    FunCheck() {
      let isCheck = false;
      if (!this.form.name) {
        // 校验学习包名称
        isCheck = true;
        this.$message.warning("请输入学习包名称");
        return isCheck;
      }
      if (!this.form.picture) {
        // 校验主图
        isCheck = true;
        this.$message.warning("请上传主图");
        return isCheck;
      }
      if (!this.form.abstracts) {
        // 校验学习包简介
        isCheck = true;
        this.$message.warning("请输入学习包简介");
        return isCheck;
      }
      if (!this.productJson.length) {
        // 校验相关商品
        isCheck = true;
        this.$message.warning("请添加相关商品");
        return isCheck;
      }
      if (this.productJson.length < 2) {
        // 校验相关商品
        isCheck = true;
        this.$message.warning("请至少添加2种商品");
        return isCheck;
      }
      if (!this.form.couponPrice) {
        // 校验入套餐价
        isCheck = true;
        this.$message.warning("请输入学习包单价");
        return isCheck;
      }
      if (this.form.couponPrice > this.form.totalPrice) {
        // 校验入套餐价
        isCheck = true;
        this.$message.warning("套餐价格需小于或等于总价格");
        return isCheck;
      }
      if (this.form.priceType == 2 && this.priceJson.length < 1) {
        isCheck = true;
        this.$message.warning("请添加购买门槛");
        return isCheck;
      }
      if (!this.form.freightId && this.freightShow) {
        // 校验费模板
        isCheck = true;
        this.$message.warning("请选择运费模板");
        return isCheck;
      }
      if (!this.form.introduce) {
        // 校验详情介绍
        isCheck = true;
        this.$message.warning("请输入详情介绍");
        return isCheck;
      }
    },

    // 提交数据
    onSavaForm() {
      if (this.FunCheck()) return; // 非空字段校验
      // 防抖
      if (this.loading) {
        return
      }
      this.loading = true;
      this.form.priceJson =
        this.form.priceType == 2 ? JSON.stringify(this.priceJson) : null;
      this.form.productJson = [];

      this.productJson.forEach((element, index) => {
        this.form.productJson.push({
          productId: element.productId,
          quantity: element.quantity,
          productType: element.productType,
          deleted: element.deleted,
        });
        if (element.id) {
          this.form.productJson[index].id = id;
        }
      });

      this.form.productJson = JSON.stringify(this.form.productJson);
      this.$ajax({
        url: this.form.id
          ? "/hxclass-management/organiz-package/edit"
          : "/hxclass-management/organiz-package/save",
        method: this.form.id ? "put" : "post",
        params: this.form, // 如果是包邮用定义包邮的对象
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.$router.go(-1);
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 富文本内容有变化时触发
    getContent(e) {
      this.form.introduce = e;
    },
    // 输入套餐价触发
    inputContent() {
      if (this.form.couponPrice > this.form.totalPrice) {
        // 设置的学习包优惠价不可高于原价
        this.$message.warning("学习包单价不可高于总价格");
      }
    },
    // 窗口确认
    relationOk(e) {
      if (!this.selectData.length) {
        this.$message.warning("请选择商品");
        return;
      }

      let arry = this.productJson;
      this.productJson = [];
      this.selectData.forEach((element) => {
        this.productJson.push({
          // 进行对所选商品赋值
          quantity: 1,
          deleted: 0,
          price: element.orgPrice,
          productId: element.id,
          productName: element.productName,
          productType: element.productType,
        });
      });

      this.total = this.productJson.length;
      this.relation = false;
      this.onSumPrice();
    },

    // 上传列表图
    customList(fileData) {
      let file = fileData.filename // 名称前缀
      this.loadingPc = true;
      const bucket = 'ueditor/product/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          if(file == 'picture'){  // 列表图
            this.form.picture = url
          } else if(file == 'detail') {  // 详情图
            this.form.detailPicture = url
          }
        } else {
          this.$message.error("上传失败");
        }
        this.loadingPc = false;
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },

    // 商品高级检索
    onProductSearch() {
      this.productForm.pageNum = 1;
      this.getProductList();
    },
    // 检索商品列表
    getProductList() {
      this.productLoad = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/organiz-package/product/win-list",
        params: this.productForm,
      }).then((res) => {
        this.productLoad = false;
        if (res.code == 200 && res.success) {
          this.productTotal = res.data.total;
          this.productData = res.data.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 选择商品
    onSelectProduct(selectedRowKeys, v) {
      this.selectedProductRowKeys = selectedRowKeys;
      this.productArry[this.productForm.pageNum - 1] = v;
      // this.selectData = []

      // 暂存数组
      let storageArry = [];
      this.productArry.forEach((item) => {
        item.forEach((subItem) => {
          storageArry.push(subItem);
        });
      });

      // 已选数组和新数组合并
      this.selectData = [...this.selectData, ...storageArry];
      let arry = [];
      selectedRowKeys.forEach((element) => {
        this.selectData.forEach((item) => {
          if (element == item.id) arry.push(item);
        });
      });

      // 并列数组去重
      let newArr = [];
      let obj = {};
      for (var i = 0; i < arry.length; i++) {
        if (!obj[arry[i].id]) {
          newArr.push(arry[i]);
          obj[arry[i].id] = true;
        }
      }

      this.selectData = newArr;

      this.selectTotal = this.selectData.length;
    },

    // 清除某项已选商品
    onSelectIndexDel(type, index) {
      this.selectedProductRowKeys.splice(index, 1);
      this.selectData.splice(index, 1);
      this.selectTotal = this.selectData.length;
      if (type == 1) {
        this.productJson.splice(index, 1);
        this.total = this.productJson.length;
      }
      this.onSumPrice();
    },

    // 查询详情
    getFormDetai() {
      this.loading = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/organiz-package/get?id=" + this.form.id,
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.form = res.data;
          res.data.productList.forEach((element) => {
            this.productJson.push({
              quantity: 1,
              deleted: 0,
              price: element.couponPrice,
              productId: element.productId,
              productName: element.productName,
              productType: element.productType,
            });
            this.selectData.push({
              quantity: 1,
              deleted: 0,
              price: element.couponPrice,
              productId: element.productId,
              productName: element.productName,
              productType: element.productType,
            });
            this.selectedProductRowKeys.push(element.productId);
          });
          this.priceJson = res.data.priceList;
          this.total = this.productJson.length;
        }
      });
    },

    // 获取运费数据
    getfreightList() {
      this.$ajax({
        url: "/hxclass-management/product-freight/manage/list",
        method: "get",
        params: {
          page: 1,
          size: 1000,
        },
      }).then((res) => {
        this.freightData = res.data.records;
      });
    },
  }, // 生命周期-实例创建完成后调用
  created() {
    if (this.$route.query.id) {
      this.form.id = this.$route.query.id;
      this.getFormDetai();
    }
  }, // 生命周期-实例挂载后调用
  mounted() {
    this.getfreightList();
    this.getProductList();
  }, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {
    relation() {
      if (!this.relation) {
        this.productForm.name = "";
        this.productForm.type = undefined;
        this.productForm.pageNum = 1;
        this.productForm.pageSize = 10;
        this.getProductList();
      }
    },
    // 配置的学习包如果有实体商品，需要配置运费
    productJson() {
      this.freightShow = false;
      this.productJson.forEach((element) => {
        if (element.productType == 2) {
          this.freightShow = true;
        }
      });
    },
  },
};
</script>

<style lang="less"  scoped>
.all_main_wrap {
  a {
    color: #333333;
  }

  .title-blod {
    margin-top: 16px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 16px;
  }

  .all_content_box {
    .L_R_inner {
      margin: 14px 24px;
      .minredtext {
        font-size: 12px;
        color: red;
      }

      .redtext {
        font-size: 14px;
        color: red;
      }

      .greytext {
        font-size: 12px;
        color: #888;
      }

      .all_left_name {
        width: 110px;
        min-width: 110px;
        text-align: right;
      }

      .align {
        width: 94px;
        text-align: right;
      }

      .vue-ueditor-wrap {
        margin-left: 130px;
        width: 800px;
      }

      .right_Div {
        textarea.ant-input {
          width: 800px;
          height: 100px;
        }

        .all_input {
          width: 337px;
        }

        /deep/.ant-input {
          height: 37px;
        }

        .else_input {
          margin-right: 12px;
          width: 140px;
        }

        .left {
          margin-left: 12px;
        }
      }

      .right-box {
        .box-first {
          width: 471px;

          .right_Div {
            display: inline-block;
            line-height: 37px;

            .ant-select {
              width: 166px;
            }
          }
        }

        .Up_Down_inner {
          margin-left: 20px;

          .all_left_name {
            display: flex;
            align-items: center;
            margin-top: 24px;

            .btn {
              margin: 0px 24px;
            }
          }

          .redtext {
            display: inline-block;
            margin-top: 24px;
            font-size: 14px;
            color: red;
          }
        }
      }
    }
  }

  .button {
    margin-left: 170px;
    .btn {
      margin: 24px 24px 24px 0;
    }
  }

  .tips {
    margin-left: 24px;
    font-size: 14px;
    color: #888;
  }
}

/deep/.ant-modal-body {
  overflow: hidden;
}

/deep/.ant-modal-title {
  text-align: center;
  color: #000000;
}

/deep/.ant-modal-header {
  border-bottom: none;
}

/deep/.ant-modal-footer {
  text-align: center;
  border-top: none;
}

/deep/ .ant-modal-body {
  padding: 14px 36px 0px;
}

.table-template {
  /deep/.ant-table {
    border: none;
    // box-shadow: none;
  }
}

.table-box {
  display: flex;
  justify-content: space-between;
  .table-template1 {
    width: 600px;
    margin-right: 20px;
  }
  .table-template2 {
    flex: 1;
  }
}

.search-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .search-input {
    width: 36%;
  }
}

.flex-box {
  display: flex;
  align-items: center;
}

// 优惠条件
.discount {
  margin-top: 10px;
  .item {
    font-size: 14px;
    margin-bottom: 15px;
    a {
      margin-left: 10px;
      color: #3681f0;
    }
  }
}

.picture {
  width: 60px;
}
</style>

